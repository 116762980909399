<template>
  <div class="replace">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="replace_search_wallet">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-bottom: 20px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <div style="display: flex">
        <el-input
          v-model="searchPhone"
          placeholder="请输入需要操作手机号"
        ></el-input>
        <el-button type="primary" @click="handleSearchPhone">查询</el-button>
        <el-button
          type="info"
          @click="handleEmpty"
          :disabled="searchPhone == ''"
          >置空</el-button
        >
      </div>
      <div class="replace_search_wallet_p">
        查询到的钱包地址是：{{ wallet }}
      </div>

      <div style="display: flex; margin-top: 10px">
        <el-input
          v-model="searchWallet"
          placeholder="请输入钱包地址"
        ></el-input>
        <el-button type="primary" @click="handleSearchWallet">查询</el-button>
        <el-button
          type="info"
          @click="handleEmpty"
          :disabled="searchWallet == ''"
          >置空</el-button
        >
      </div>
      <div class="replace_search_wallet_p">查询到的手机号是：{{ phone }}</div>
    </div>

    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item prop="phoneArea">
        <el-select
          v-model="valueArea2"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="url">
        <el-input v-model="ruleForm.url" placeholder="新绑定的地址"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >强制修改</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: { Breadcrumb },
  inject: ["reload"],
  data() {
    var validatePhone = (rule, value, callback) => {
      var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("号码不能为空!!"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式有误"));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      ruleForm: {
        phone: "",
        url: "",
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
      },

      valueArea: "",
      valueArea2: "",
      options: [],
      searchPhone: "",
      searchWallet: "",
      phone: "",
      wallet: "",

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "CPLE质押",
        },
        {
          name: "强制绑定/替换钱包地址",
        },
      ], // 面包屑数据

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt = {
            url: reqUrl.updateWalletUrl,
            method: "POST",
            params: JSON.stringify({
              auditor: localStorage.getItem("userName"),
              field: this.ruleForm.url,
              nonce: "",
              phone: this.ruleForm.phone,
              phoneArea: this.valueArea2 || this.phoneAreaROLE,
              sign: "",
              token: "",
            }),
            resFunc: (res) => {
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.ruleForm.phone = "";
                this.ruleForm.url = "";
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        } else {
          this.$notify.error({
            title: "错误",
            message: "请检查输入是否正确",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.searchPhone = "";
      this.wallet = "";
    },

    handleSearchPhone() {
      if (this.searchPhone == "") {
        this.$message.error("请输入需要查询的手机号");
      } else if (this.searchPhone != "") {
        showLoading();
        const opt = {
          url: reqUrl.selectWalletUrl,
          method: "POST",
          params: JSON.stringify({
            nonce: "",
            phone: this.searchPhone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.wallet = res.data.data.wallet;
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      }
    },

    handleSearchWallet() {
      if (this.searchWallet == "") {
        this.$message.error("请输入需要查询的钱包地址");
      } else if (this.searchWallet != "") {
        showLoading();
        const opt = {
          url: reqUrl.selectWalletUrl,
          method: "POST",
          params: JSON.stringify({
            wallet: this.searchWallet,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.phone = res.data.data.phone;
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      }
    },

    handleEmpty() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.replace {
  width: 100%;

  .el-form {
    .el-input {
      width: 280px;
    }
  }

  .replace_search_wallet {
    width: 40%;
    margin-left: 100px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px #ccc solid;

    .el-input {
      width: 280px;
      margin-right: 10px;
    }

    .replace_search_wallet_p {
      color: #333;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
